<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            ref="filterModal"
        >
            <template v-slot:filters>
                <Dropdown
                    name="manCode"
                    placeholder="請輸入業務姓名或帳號"
                    :required="true"
                    :searchable="true"
                    :title="filterTitles.manCode"
                    :options="filterOptions.manCode"
                    :hasSelectedALL="false"
                    v-model="filterPayload.manCode"
                />
                <Textbox
                    name="validDate"
                    type="date"
                    :title="filterTitles.validDate"
                    :required="true"
                    v-model="filterPayload.validDate"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import _ from 'lodash'

import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'

import { InsuranceListFilterTitles } from '@/assets/javascripts/property/setting'
import filterDefault from '@/assets/json/filterDefault.json'
import { getAccountCodesAPI } from '@/assets/javascripts/api'

export default {
    name: 'InsuranceListFilter',
    emits: [
        'show-data-table',
        'update:filterLogProp',
        'update:filterPayloadProp'
    ],
    components: {
        Dropdown,
        FilterModal,
        Textbox
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: '',
                    validDate: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        /**
         * 重置篩選內容
         */
        resetFilter: function () {
            this.filterPayload = { ...filterDefault.propertyInsuranceList }
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        /**
         * 取得篩選結果
         */
        getFilterData: async function () {
            const isSameValue = this.$isSameValue(
                this.perviousFilterPayload,
                this.filterPayload
            )
            if (!isSameValue) {
                this.perviousFilterPayload = _.cloneDeep(this.filterPayload)
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            // 增加label用於組成pdf file name
            const selected = this.filterOptions.manCode.find(
                (o) => o.value === this.filterPayload.manCode
            )
            this.filterLog.name = selected.label
        },
        /**
         * 取得 man code label
         * @param data code data
         */
        getManCodeLabel: function (data) {
            const quitBackRegex = /-[A-Z]/

            if (quitBackRegex.test(data.ID.slice(10))) {
                return `${data.value}_${data.ID}`
            } else {
                return `${data.value}_${data.ID.slice(0, 10)}`
            }
        },
        getOptions: async function () {
            try {
                this.$showLoading()
                const payload = { isFullIdentity: true }
                const response = await getAccountCodesAPI(payload)
                this.accounts = response.data.accounts
            } catch (error) {
                this.codes = []
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            const hasMainAccount = this.accounts.some(
                (acc) => acc.isMainAccount
            )
            const manCode = _.orderBy(
                this.accounts.reduce((acc, account) => {
                    // 沒有主帳號 且 有指定招攬人主帳號
                    const showApStuMainAccount =
                        !hasMainAccount && account.isApStuMainAccount

                    if (account.isMainAccount || showApStuMainAccount) {
                        acc.push({
                            label: this.getManCodeLabel(account),
                            value: account.key,
                            titleTypeId: 'all'
                        })
                    }
                    return acc
                }, []),
                'label',
                'asc'
            )
            return {
                manCode
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true,
            immediate: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterOptions.manCode': {
            handler() {
                // 業務員預設選擇自己
                if (
                    this.$store.state.userInfo.isSales &&
                    this.filterOptions.manCode.length
                ) {
                    this.filterPayload.manCode = this.filterOptions.manCode[0].value
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            isLoading: false,
            filterPayload: this.filterPayloadProp,
            perviousFilterPayload: {},
            filterLog: this.filterLogProp,
            filterTitles: InsuranceListFilterTitles,
            accounts: []
        }
    },
    mounted() {
        this.getOptions()
    }
}
</script>

<style lang="scss" scoped></style>
